@import "_colors";

@mixin flexWidth($widths...) {
    @each $width in $widths {
        &.flex-#{round($width)} {
            flex-basis: #{percentage($width/100)};
        }
    }
}

@mixin dropDown {
    select {
        -webkit-appearance: none;
        -moz-appearance: none;
        appearance: none;
        background-image:
          linear-gradient(45deg, transparent 50%, gray 50%),
          linear-gradient(135deg, gray 50%, transparent 50%);
        background-position:
          calc(100% - 15px) calc(1em + 2px),
          calc(100% - 10px) calc(1em + 2px);
        background-size:
          5px 5px,
          5px 5px;
        background-repeat: no-repeat;
      }
}

@mixin shadow {
    box-shadow: $ex-light-grey 1px 1px 15px;
}

@mixin footerMask {
    &:before {
        content:'';
        display: block;
        width: 100vw;
        height: 10rem;
        position: fixed;
        bottom: 0;
        left: 0;
        z-index: 4;
        background: rgb(33,33,38);
        background: linear-gradient(0deg, rgba(33,33,38,1) 25%, rgba(33,33,38,0) 100%);
    }
}

// Turn white to orange
@mixin filterOrange {
  filter: brightness(0) saturate(100%) invert(39%) sepia(76%) saturate(1235%) hue-rotate(353deg) brightness(85%) contrast(95%);
}