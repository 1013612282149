$footerHeight: 80px;
$headerHeight: 60px;
$contentHeight: calc(100vh - #{$footerHeight} - #{$headerHeight});

@import "_breakpoints";
@import "_colors";
@import "_mixins";

@import url('//fonts.googleapis.com/css2?family=Poppins:ital,wght@0,200;0,300;0,700;1,300&display=swap');

body, html{
  font-weight: 200;
  font-family: 'Poppins', sans-serif;
  font-size: 10px;
  background-color: $black;
  color: white;
  margin: 0;
//   overscroll-behavior-y: contain;
}

p {
    font-size: 1.7rem;
}

.orange {
    color:  $orange-dk;
}

a {
    color: white;
    text-decoration: none;
    &:visited, &:focus, &:hover {
        color: white;
    }
}

@include respond-to-min('large') {
    .container {
        max-width: 600px;
    }
}
@include respond-to-max('mobile') {
    .container {
        max-width: 100%;
        padding: 0;
        margin: 0 !important;
    }
}

.icon-r90 {
    transform: rotate(90deg);
}

.icon-r180 {
    transform: rotate(180deg);
}

.icon-r270 {
    transform: rotate(270deg);
}

.icon-text {
    font-weight: 700;
    font-size: 1em;
}

.helper-text, .cancel, .alter {
    text-transform: uppercase;
    text-align: center;
    font-size: 1.2rem;
    position: relative;
    z-index: 20;
    color: white;
}

.helper-text {
    margin-top: -1.2rem;
}

#welcome {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%;
    height: 90vh;
    .logo {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        flex: 1 1 auto;
        font-weight: 700;
        text-transform: uppercase;
        text-align: center;
        img {
            width: 30vw;
            margin-bottom: 5rem;
        }
    }
    .made-by {
        height: 150px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        text-align: center;
        .shs {
            font-size: 1.5rem;
        }
    }
}

#app {
    margin: 0 auto;
    max-width: 500px;
    // height: 100vh;
    display: flex;
    flex-direction: column;
    .header {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        position: relative;
        z-index: 20;
        // height: 60px;
        height: $headerHeight;
        img {
            align-items: center;
            width: auto;
            height: 40px;
            margin-top: 10px;
            display: block;
            margin: 10px auto 0 auto;
        }
    }
    .content {
        flex: 1 1 auto;
        // min-height: calc(100vh - 140px);
        min-height: $contentHeight;
        // overflow: scroll;
        padding: 0;
    }
    .footer {
        background: transparent;
        position: fixed;
        left: 0;
        bottom: 0;
        width: 100%;
        // height: 80px;
        height: $footerHeight;
        z-index: 10;
        display: flex;
        justify-content: center;
        align-items: center;
        .menu {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            height: 100%;
            width: 100%;
            max-width: 500px;
            position: relative;
            z-index: 2;
            a.item {
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;
                flex: 1;
                text-decoration: none;
                img {
                    height: 3.2rem;
                }
            }
        }
    }
}



.day-view {
    min-height: $contentHeight;
    h1, .h1 {
        position: relative;
        // top: 10vh;
        padding-top: 10rem;
    }
    .completed {
        height: 100%;
        position: relative;
        padding-top: 10rem;
        &.drank {
            &:before {
                content: '';
                display: block;
                height: 100vh;
                width: 100vw;
                background: rgb(194,98,23);
                background: linear-gradient(0deg, rgba(194,98,23,1) 0%, rgba(168,57,13,1) 100%);
                left: 0;
                top: 0;
                position: fixed;
                pointer-events: none;
                z-index: 0;
            }
        }
        .drank-status {
            font-size: 2.75rem;
            line-height: 1.2;
            padding: 0 .5em;            
            font-weight: 700;
            text-align: center;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            height: 100%;
            position: relative;
            z-index: 1;
            img {
                width: 5rem;
                height: 5rem;
                margin-bottom: 1em;
            }
            .alter {
                font-size: 1.6rem;
                font-weight: 400;
                margin-top: 7vh;
            }
            .streak {
                margin-top: 5rem;
                display: flex;
                flex-direction: column;
                padding: 1em;
                border: 1px solid white;
                background-color: rgba(61,65,71, .5);
                .result {
                    font-size: 2.75rem;
                    font-weight: 400;
                }
                .count {
                    font-size: 3rem;
                }
                a {
                    display: block;
                    margin-top: 3rem;
                    border: thin solid white;
                    padding: .25em;
                    font-weight: 400;
                    text-decoration: none;
                }
            }
        }
    }
    #drink-fill-wrapper {
        position: fixed;
        top: 0;
        left: 0;
        height: 100vh;
        width: 100%;
        transition: height 0.2s ease;
        margin-top: 0;
        z-index: 1;
        .drink-fill {
            height: 100vh;
            width: 100vw;
            background-image: url('../imgs/drink-fill-01.svg');
            background-repeat: no-repeat;
            background-size: 300%;
            background-position: 0 50vh;
            pointer-events: none;
            z-index: 0;
            animation-name: MOVE-BG;
            animation-duration: 5s;
            animation-timing-function: ease-in-out;
            animation-iteration-count: infinite;
            transition: background-position 0.5s ease;
        }
        &.yes {
            .drink-fill {
                background-position: 0 -5vh;
            }
        }
        &.no {
            .drink-fill {
                background-position: 0 100vh;
            }
        }
    }
    .quiz-btn-wrapper  {
        padding-top: 10rem;
        position: relative;
        // position: fixed;
        width: 100%;
        height: 20rem;
        // top: calc(50vh - 10rem);
        z-index: 2;
        text-align: center;
        font-weight: 700;
        text-transform: uppercase;
        &.selected {
            .label {
                display: none;
            }
        }
        .label {
            width: 20rem;
            margin: 0 auto;
            border: thin solid rgba(255,255,255,.5);
            height: 10rem;
            font-size: 2.4rem;
            transition: all .5s ease;
            line-height: 1;
            display: flex;
            justify-content: center;
            align-items: center;
            &:first-child {
                border-bottom-color: transparent;
            }
            &:last-child {
                border-top-color: transparent;
            }
            &.active {
                display: flex;
                border-color: rgba(255,255,255,.5);
            }
        }
    }
    .quiz {
        .helper-text {
            margin-top:  -6rem;
        }
        h1 {
            text-align: center;
            font-size: 2.75rem;
            line-height: 1.2;
            position: relative;
            z-index: 2;
        }
        .quiz-btn {
            &.yes {
                &.active {
                    background-color: #4682B4;
                    color: white;
                }
            }

            &.no {
                &.active {
                    background-color: #DB810B;
                    color: white;
                }
            }
        }
        .quiz-submit {
            text-align: center;
            font-weight: 700;
            color: white;
            font-size: 2.4rem;
            position: relative;
            z-index: 20;
            border: thin solid white;
            padding: .5em 3.5em;
            width: fit-content;
            margin: 4vh auto 0 auto;
            display: flex;
            align-items: center;
            &:after {
                content: '';
                display: block;
                width: 1em;
                height: 1em;
                margin-left: 1em;
                background-repeat: no-repeat;
                background-position: center center;
                background-size: contain;
                background-image: url('../imgs/icon-arrow-01.svg');
                position: absolute;
                right: 1em;
            }
        }
        .cancel {
            width: 100%;
            text-align: center;
            display: block;
            font-weight: 400;
            margin-top: 1.5em;
        }
        .counter-holder {
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;
            align-items: center;
            justify-content: center;
            position: relative;
            z-index: 100;
            margin-top: 4rem;
            margin-bottom: 6rem;
            .drink-counter {
                opacity: 1;
                width: 75px;
                margin: 1rem;
                position: relative;

                .count {
                    position: absolute;
                    font-size: 2.5rem;
                    font-weight: 700;
                    top: 2rem;
                    width: 100%;
                    text-align: center;
                }

                &.active {
                    opacity: .5;

                    img {
                        // https://stackoverflow.com/questions/22252472/how-to-change-the-color-of-an-svg-element
                        // https://codepen.io/sosuke/pen/Pjoqqp
                        // filter: brightness(0) saturate(100%) invert(9%) sepia(13%) saturate(552%) hue-rotate(201deg) brightness(96%) contrast(89%);
                        // filter: brightness(0) saturate(100%) invert(59%) sepia(74%) saturate(1276%) hue-rotate(168deg) brightness(90%) contrast(93%);
                    }

                    // color: #212126;
                    // color: #1172BA;
                }
            }

        }
    }
}

#firebaseui-auth-container {
    ul {
        list-style: none;
        padding: 0;
        .firebaseui-list-item {
            img {
                height: 25px;
                margin-right: 10px;
                filter: brightness(0) saturate(100%) invert(100%) sepia(0%) saturate(0%) hue-rotate(236deg) brightness(102%) contrast(103%);
            }
            button {
                border: thin solid white;
                display: flex;
                align-items: center;
                padding: 1rem;
                width: 100%;
                justify-content: center;
                margin-bottom: 1rem;
                background-color: transparent !important;
                font-size: 1.5rem;
                color: white;
                &:hover,
                &:focus {
                    cursor: pointer;
                    background-color: #EFEFEF !important;
                    color: black;
                    img {
                        filter: brightness(100%) saturate(100%) invert(100%) sepia(0%) saturate(0%) hue-rotate(236deg) brightness(102%) contrast(103%);
                    }
                }

                .firebaseui-idp-text-short {
                    display: none;
                }
            }
            
        }
    }
}

.stripe-form {
    input {
        background-color: red !important;
    }
}

.clickable {
    &:hover {
        cursor: pointer;
        text-decoration: underline;
    }
}

#goal-holder {
    max-width: 400px;
    margin: 0 auto;
    // width: 100vw;
    width: 100%;
    padding-top: 5rem;
    @include footerMask;
    .goal-nav {
        height: 13rem;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        padding: 0 5em;
        .title {
            display: flex;
            flex-direction: column;
            text-align: center;
            img {
                height: 7.5rem;
            }
            span {
                margin-top: .5em;
                font-size: 2rem;
            }
        }
        .arrow {
            img {
                width: 2.4rem;
            }
            &.disabled {
                pointer-events: none;
                img {
                    opacity: 0;
                }
            }
        }
    }
}

.goals-wrapper {
    @include footerMask;
    .status {
        text-align: center;
        .value {
            // font-size: 20vw;
            font-size: 10rem;
            font-weight: 700;
            span {
                font-weight: 100;
            }
        }
        .label {
            font-size: 2rem;
            font-weight: 700;
        }
        .graph {
            margin: 5rem auto 0 auto;
            &.glasses {
                display: flex;
                justify-content: center;
                flex-wrap: wrap;
                .day {
                    width: 10%;
                    padding-top: 10%;
                    margin: 0 2% 2rem 2%;
                    position: relative;
                    background-color: rgba(255,255,255,.2);
                    img {
                        max-width: 100%;
                    }
                    &.orange, &.white {
                        padding-top: 0;
                        background-color: transparent;
                    }
                    &.orange {
                        @include filterOrange;
                    }
                }
            }
            &.half-full {
                width: 80%;
                display: flex;
                align-items: center;
                .graph-wrapper {
                    // width: calc(100% - 24vw);
                    width: 100%;
                    height: 2rem;
                    // height: 5vw;
                    background-color: white;
                    position: relative;
                    .graph-fill {
                        position: absolute;
                        left: 0;
                        top: 0;
                        height: 100%;
                        background-color: $orange-md;
                    }
                }
                img {
                    margin: 0 2%;
                    width: 10%;
                    // width: 8vw;
                    // height: 8vw;
                    // margin: 0 2vw;
                }
            }
        }
    }
    .badges {
        margin: 5rem 1rem;
        display: flex;
        flex-wrap: wrap;
        .badge {
            margin-bottom: 2rem;
            &:nth-child(3n + 2) {
                margin-left: 2%;
                margin-right: 2%;
            }
        }
    }
}

.filter-orange {
    @include filterOrange;
}

.badge {
    width: 32%;
    display: block;
    position: relative;
    padding-top: 1rem;
    &:before, &:after {
        content: '';
        display: block;
        left: 0;
        width: 100%;
        z-index: 1;
    }
    &:before {
        top: 0;
        padding-top: 125%;
        background-color: $orange-md;
    }
    &:after {
        padding-top:40%;
        background-color: $orange-md;
        margin-top: -2px;
        clip-path: polygon(0% 0%, 100% 0%, 100% 100%, 50% 10%, 0% 100%, 0% 0%);
    }
    & > div {
        position: absolute;
        top: 2rem;
        left: 0;
        width: 100%;
        div, img {
            text-align: center;
            position: relative;
            z-index: 2;
            color: white;
            font-size: 4rem;
            font-weight: 700;
        }
        img {
            display: block;
            width: 50%;
            margin: 0 auto;
        }
    }
    &.green {
        &:before, &:after {
            background-color: $green;
        }
    }
    &.blue {
        &:before, &:after {
            background-color: $blue;
        }
    }
}

// Not used right now but leaving in case we bring reports back
#report-holder {
    max-width: 400px;
    margin: 0 auto;
    width: 100vw;
    @include footerMask;
    .report-nav {
        height: 4.2rem;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        padding: 0 5em;
        .chart-title {
            text-transform: uppercase;
            font-size: 1.6rem;
            font-weight: 700;
            letter-spacing: .05em;
        }

        .arrow {
            img {
                width: 2.4rem;
            }
            &.disabled {
                pointer-events: none;
                img {
                    opacity: .3;
                }
            }
        }
    }
    .chart-holder {
        margin: 0 auto;
        max-width: 100%;
    }
    .summary {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        padding: 0 .7rem;
        margin-bottom: 1.6rem;
        .item {
            display: flex;
            flex-direction: column;
            justify-content: flex-start;
            align-items: center;
            text-transform: lowercase;
            text-align: center;
            flex: 1 0 30%;
            font-weight: 700;
            .count {
                font-size: 3rem;
                line-height: 1;
            }
            .days, .label {
                font-size: 1.6rem;
                line-height: 1.2;
            }
            &.sober {
                color: white;
            }
            &.drunk {
                color: $orange-md;
            }
            &.no-data {
                color: $grey-md;
            }
        }
    }
    .controls {
        width: 100%;
        text-align: center;
        color: $grey-md;
    }
}

.weekdays {
    padding: 3.2rem 1.6rem;
    .day-of-week {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        margin-bottom: .7rem;
        .title {
            width: 40%;
            color: $grey-md;
            font-weight: 400;
        }
        .all-days {
            width: 100%;
            height: 3.2rem;
            background-color: #CCC;
            border: thin solid #1E2A39;
            display: flex;
            flex-direction: row;
            .data {
                height: 3.2rem;
                &:last-child {
                    border: none;
                }
                &.sober {
                    background-color: rgba(255,255,255,.1);
                }
                &.drunk {
                    background-color: $orange-md;
                }
                &.no-data {
                    background-color: $grey-lt;
                }
            }
        }
    }
}

.text-center {
    text-align: center !important;
}

.back-btn {
    display: block;
    font-size: 1.6rem;
    margin: 4rem 0;
}

.onboard {
    width: 100%;
    padding: 5rem 2rem;
    h1 {
        text-align: center;
        font-weight: 700;
        font-size: 3rem;
    }
    h2 {
        text-align: center;
        font-weight: 700;
        margin-bottom: -2rem;
    }
    hr {
        margin: 2rem 0;
    }
    .image-wrapper {
        margin: 1rem auto 2rem auto;
        display: flex;
        justify-content: center;
        img {
            display: block;
            height: 8rem;
            margin: 0 -1.5rem;
        }
    }
    .prompt {
        margin-bottom: 2rem;
        font-size: 1.6rem;
        line-height: 1.4;
        p {
            text-align: left;
        }
    }
    .options {
        display: flex;
        flex-direction: column;
        margin: 5rem auto 0 auto;
        .item {
            padding: .5rem;
            display: flex;
            align-items: flex-start;
            padding-bottom: 1rem;
            padding-top: 1rem;
            border-top: 1px dashed $grey-lt;
            &:last-child {
                border-bottom: 1px dashed $grey-lt;
            }
            .icon {
                width: 5rem;
            }
            .description {
                padding-left: 1rem;
                width: 100%;
            }
            .title {
                color: white;
                font-size: 2rem;
                font-weight: 700;
            }
            p {
                margin-top: 0;
                margin-bottom: .5rem;
            }
            &.active {
                .title {
                    color: $orange-md;
                }
                img {
                    // https://stackoverflow.com/questions/22252472/how-to-change-the-color-of-an-svg-element
                    // https://codepen.io/sosuke/pen/Pjoqqp
                    filter: brightness(0) saturate(100%) invert(33%) sepia(86%) saturate(1277%) hue-rotate(356deg) brightness(96%) contrast(90%);
                }
            }
        }
        .description {
            p, span {
                font-size: 1.2rem;
                line-height: 1.2;
                margin-top: .25em;
                // color: $grey-md;
            }
            &.legible {
                p, span {
                    font-size: 2rem;
                    color: white;
                }
            }
        }
    }
    form {
        margin-top: 4rem;
        .submit {
            overflow: auto;
        }
    }
    .form-input {
        text-align: center;
        margin: 6rem auto;
        input {
            background-color: transparent;
            color: black;
            height: 4.2rem;
            padding: .5em 1em;
            font-size: 3rem;
            border: none;
            filter: invert(100%) saturate(100%);
            &:focus {
                border: none;
                outline-color: none;
            }
        }
    }
    .form-label  {
        text-transform: uppercase;
        letter-spacing: .05em;
    }
    .form-control  {
        background-color: $grey-lt;
        border-radius: 0;
        padding: 1rem  1em;
        color: white;
        font-size: 1.5rem;
    }
    .btn {
        background-color: transparent;
        border: 1px solid white;
        color: white;
        font-size: 1.5rem;
        padding: .5em 1em;
        // float: right;
        margin-top: 1rem;
        // margin-left: 1rem;
    }
    .toggle {
        text-align: center;
        margin-top: 3rem;
        height: 2rem;
        line-height: 1;
    }
    .skip-link {
        display: flex;
        align-items: center;
        margin-top: 2rem;
        width: 100%;
        justify-content: center;
    }
    .next-btn {
        width: 100%;
        font-size: 2.5rem;
        font-weight: 700;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        padding: .25em 0;
        margin-top: 2em;
        border: 1px solid white;
        &.disabled, &:disabled {
            color: $grey-md;
            border-color: $grey-md;
            opacity: 1;
            // display: none;
        }
        &.active {
            color: white;
        }
    }
    .login-options {
        .btn {
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
        }
    }
}



.days-key {
    margin-top: 4.2rem;
    .item {
        display: flex;
        flex-direction: row;
        align-items: center;
        color: white;
        font-weight: 700;
        letter-spacing: .05em;
        margin-bottom: .5em;
        .swatch {
            width: 1.6rem;
            height: 1.6rem;
            border: 1px solid #1E2A39;
            margin-right: .5em;
            &.sober {
                background-color: white;
            }
            &.drunk {
                background-color: $orange-md;
            }
            &.no-data {
                background-color: $grey-md;
            }
        }
    }
}



.chart-tooltip {
    font-size: 1.6rem;
    background-color: $black;
    padding: .25em 1em;
    border: 1px solid white;
    display: flex;
    flex-direction: row;
    align-items: center;
    .swatch {
        width: 1.2em;
        height: 1.2em;
        margin-right: .5em;
    }
}

.history {
    padding: 2rem 2% 5rem 2%;
    display: flex;
    flex-wrap: wrap;
    position: relative;
    // overflow-y: scroll;
    @include footerMask;
    a {
        color: $grey-md;
        font-size: 1.2rem;
        &.date {
            position: relative;
            padding: .8em;
            border: thin solid $grey-lt;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            font-weight: 400;
            background-color: $grey-lt;
            width: 32%;
            margin-bottom:  2%;
            &:nth-child(3n + 4), &:nth-child(1) {
                margin-right: 1%;
                margin-left: 0;
            }
            &:nth-child(3n + 2) {
                margin-left: 1%;
                margin-right: 1%;
            }
            &:nth-child(3n + 3) {
                display: flex;
                margin-left: 1%;
                margin-right: 0;
            }
            &:nth-child(n+4) {
                max-width: 32%;
            }
            &.sober {
                background-color: white;
                color: $grey-md;
                .label-icon {
                    img {
                        filter: brightness(0) saturate(100%) invert(22%) sepia(7%) saturate(778%) hue-rotate(177deg) brightness(93%) contrast(85%);
                    }
                }
            }

            &.drunk {
                background-color: $orange-md;
                color: white;
            }
            span {
                &.label-day {
                    font-weight: 700;
                    font-size: 1.8rem;
                }
                &.label-icon {
                    img {
                        width: 3rem;
                        margin-top: .5em;
                        margin-bottom: .5em;
                    }
                }
            }
        }
    }   
}


@keyframes MOVE-BG {
   0% {
     transform: scaleX(1) translateX(0);
   }
   20% { 
     transform: scaleX(5) translateX(-10%);
   }
   40% { 
     transform: scaleX(2) translateX(-5%);
   }
   60% { 
     transform: scaleX(5) translateX(-10%);
   }
   80% { 
     transform: scaleX(5) translateX(0%);
   }
   100% {
    transform: scaleX(1) translateX(0);
   }
}