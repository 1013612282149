@import "_colors";

form {
    .DraftEditor-root {
        border: 1px solid #9da2aa;
        padding: 1rem;
        font-size: 1.4rem;
    }
}

.rtf {
    // width: 100%;
}

.wysiwyg-controls {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    padding: .45rem .67rem;
    border: 1px solid #999999;
    border-bottom: none;
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
    svg {
        margin-left: .75rem;
    }
}