$black: rgb(33,33,38);

$orange-dk: rgb(168,57,13);
$orange-md: rgb(212,85,19);
$orange-lt: rgb(194,98,23);

$blue: #0071BC;
$green: #39B54A;

$grey-lt: #3d4147;
$grey-md: rgb(128,128,128);

$dark-grey: rgb(77, 77, 77);
$medium-grey: rgb(173, 173, 173);
$light-grey: rgb(232, 232, 232);
$ex-light-grey: rgb(242, 242, 242);
