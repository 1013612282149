@import "../../../node_modules/bootstrap/scss/bootstrap-grid.scss";
// required for bootstrap
@import "../../../node_modules/bootstrap/scss/functions";
@import "../../../node_modules/bootstrap/scss/variables";
@import "../../../node_modules/bootstrap/scss/mixins";
// @import "../../../node_modules/bootstrap/scss/root";

// // optional bootstrap packages
@import "../../../node_modules/bootstrap/scss/reboot";
// @import "../../../node_modules/bootstrap/scss/type";
// @import "../../../node_modules/bootstrap/scss/images";
// @import "../../../node_modules/bootstrap/scss/containers";
// @import "../../../node_modules/bootstrap/scss/grid";
@import "../../../node_modules/bootstrap/scss/forms";
// @import "../../../node_modules/bootstrap/scss/flex";
// @import "../../../node_modules/bootstrap/scss/helpers";
@import "../../../node_modules/bootstrap/scss/utilities";
